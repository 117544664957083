@import url("https://use.typekit.net/kmp8rqf.css");
@font-face {
  font-family: Universe;
  src: url("../fonts/universe.otf") format("opentype");
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #0d0d0d;
  font-family: "century-gothic", sans-serif;
  overflow: hidden;
}

a, button {
  font-family: "century-gothic", sans-serif;
}
a:focus, button:focus {
  outline: none;
}

.showMobile {
  display: none;
}

.hideMobile {
  display: block;
}

.particles {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100vh;
}

#container {
  opacity: 1;
  transition: all 2s ease-in-out;
}

#stage {
  position: absolute;
  height: 100vh;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: url("../img/bg.png") no-repeat scroll bottom center/cover;
  transition: all 1s ease-in-out;
  pointer-events: none;
}
#stage.open {
  transform: translate(0, 55vh);
  transition: all 1.2s ease-in-out;
}

#App {
  opacity: 0;
  transition: all 3s ease-in-out;
}
#App.in {
  opacity: 1;
  transition: all 3s ease-in-out;
}
#App.in #logo {
  left: 0;
  transition: all 2.5s ease-in-out;
}
#App.in nav {
  right: 0;
  transition: all 2.5s ease-in-out;
}
#App.in #social {
  top: 25px;
  transition: all 2.5s ease-in-out;
}
#App.in #legal, #App.in .emailForm {
  bottom: 0;
  transition: all 2.5s ease-in-out;
}

#splash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  overflow: hidden;
  background: #faf9ee;
}
#splash.close {
  transform: translate(0, -100vh);
  transition: all 0.3s ease-in-out;
}
#splash .half {
  width: 50%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
#splash #artwork {
  position: absolute;
  right: 50px;
  top: 0;
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#splash #artwork img {
  max-width: 100%;
}
#splash button {
  background: #faf9ee;
  border: none;
  position: absolute;
  top: 25px;
  right: 25px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 5px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  font-weight: bold;
}
#splash button:hover {
  cursor: pointer;
  letter-spacing: 5.5px;
  transition: all 0.3s ease-in-out;
}
#splash #title {
  width: 80%;
  transform: translate(0, -25px);
}
#splash #logoMarks {
  position: absolute;
  left: 25px;
  bottom: 25px;
  height: auto;
  z-index: 999;
  width: 200px;
}
#splash #logoMarks img {
  width: 40px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  opacity: 0.3;
}
#splash #logoMarks img#aftermath {
  width: 25px;
}
#splash #musicLinks {
  display: block;
  text-align: center;
  transform: translate(0, -35px);
}
#splash #musicLinks p {
  text-transform: uppercase;
  letter-spacing: 10px;
  margin: 0;
  color: #818389;
  line-height: 60px;
}
#splash #musicLinks p.date {
  font-weight: bold;
  font-size: 24pt;
}
#splash #musicLinks p.order {
  font-size: 12pt;
  font-weight: lighter;
}
#splash #musicLinks a {
  width: 35px;
  display: inline-block;
  margin: 0 auto;
  padding: 0 10px;
  vertical-align: middle;
}
#splash #musicLinks a#apple {
  width: 75px;
}
#splash #musicLinks a img {
  width: 100%;
  height: auto;
}
#splash #musicLinks a#shop {
  display: block;
  width: auto;
  text-decoration: none;
  text-transform: uppercase;
  color: #818389;
  font-size: 24pt;
  margin-top: 60px;
  letter-spacing: 5px;
}
#splash #musicLinks a#shop span {
  font-size: 26pt;
}
#splash .image {
  position: relative;
  z-index: 1;
}
#splash .image video {
  border: none;
  height: auto;
}

#logo {
  position: fixed;
  left: -100%;
  padding-left: 25px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 99;
  pointer-events: none;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  transition: all 2.5s ease-in-out;
}
#logo img {
  width: 120px;
  height: auto;
}

nav {
  position: fixed;
  top: 0;
  right: -100%;
  padding-right: 25px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 12;
  pointer-events: none;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  transition: all 2.5s ease-in-out;
}
nav.hideMobile {
  display: flex !important;
}
nav button, nav a {
  display: block;
  border: none;
  background: none;
  color: white;
  margin: 0;
  padding: 0;
  font-size: 14pt;
  font-weight: bold;
  width: 200px;
  text-align: right;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  pointer-events: auto;
}
nav button:hover, nav a:hover {
  cursor: pointer !important;
  color: #EFB123;
  transition: all 0.3s ease-in-out;
}
nav button.selected, nav a.selected {
  color: #EFB123;
}

#social {
  position: fixed;
  top: -100%;
  right: 25px;
  z-index: 999;
  transition: all 2.5s ease-in-out;
}
#social a {
  display: inline-block;
  margin: 0 8px;
}
#social a#facebook {
  width: 10px;
  height: auto;
}
#social a#instagram {
  width: 18px;
  height: auto;
}
#social a#twitter {
  width: 22px;
  height: auto;
}
#social a#youtube {
  width: 55px;
  height: auto;
  margin: 0;
}
#social a img {
  width: 100%;
  height: auto;
}
#social a.showMobile {
  display: none;
}
#social a.hideMobile {
  display: inline-block;
}

#legal {
  position: fixed;
  bottom: -100%;
  right: 25px;
  display: block;
  color: white;
  font-size: 8pt;
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 20;
  text-align: right;
  transition: all 2.5s ease-in-out;
}
#legal span {
  font-size: 6pt;
  display: block;
  line-height: 15px;
}
#legal a {
  height: auto;
  display: inline-block;
  margin: 0 auto;
  text-align: right;
  font-weight: bold;
  color: white;
  text-decoration: none;
}
#legal a img {
  vertical-align: middle;
}

::placeholder {
  color: white;
  opacity: 1;
}

.emailForm {
  display: block;
  position: fixed;
  bottom: -100%;
  left: 0;
  z-index: 11;
  padding: 25px;
  transition: all 2.5s ease-in-out;
  -webkit-overflow-scrolling: touch;
}
.emailForm h6 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  font-size: 9px;
  letter-spacing: 1px;
}
.emailForm input {
  background: none;
  border: none;
  border-bottom: 1px solid white;
  width: 175px;
  line-height: 24px;
  transform: translate(0, -1px);
  color: white;
  border-radius: 0;
  font-size: 16px;
  font-family: "century-gothic", sans-serif;
}
.emailForm input:focus {
  outline: none;
  background: none;
}
.emailForm button {
  width: auto;
  background: white;
  text-decoration: none;
  color: black;
  font-size: 8pt;
  letter-spacing: 1px;
  line-height: 25px;
  padding: 0 15px;
  margin: 0 0 0 7px;
  transform: translate(0, -1px);
  border: none;
  text-align: center;
  display: table-cell;
  transition: all 0.3s ease-in-out;
}
.emailForm button:hover {
  background: #EFB123;
  transition: all 0.3s ease-in-out;
}
.emailForm .msg-alert p {
  color: #EFB123 !important;
  margin: 5px 0 0;
  transition: all 1s ease-in-out;
}

#modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  transform: translate(0, -100vh);
  transition: all 1s ease-in-out;
  opacity: 0;
}
#modal.open {
  transform: translate(0, 0);
  transition: all 1.5s ease-in-out;
  opacity: 1;
}
#modal h2 {
  font-size: 12pt;
  color: white;
  letter-spacing: 5px;
  text-align: center;
  text-transform: uppercase;
  margin: 25px 0 15px;
  padding: 0;
  font-weight: bold;
}
#modal #music,
#modal #videos,
#modal #photos,
#modal #tour,
#modal #download {
  display: none;
  height: 100%;
}
#modal #music.show,
#modal #videos.show,
#modal #photos.show,
#modal #tour.show,
#modal #download.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#modal #music {
  text-align: center;
  min-height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0 200px 150px;
  width: 100%;
  transform: scale(1);
  -ms-transform: scale 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#modal #music.show {
  display: block;
  justify-content: flex-start;
}
#modal #music .album {
  display: inline-block;
  margin: 25px;
  width: 250px;
  text-align: center;
  margin: 25px 25px 0;
}
#modal #music .album img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
#modal #music .album a {
  width: 20px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  text-align: center;
}
#modal #music .album a.apple {
  width: 50px;
}
#modal #tour {
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#modal #tour ul {
  list-style: none;
  margin: 0 0 -100px;
  padding: 0;
  transform: scale(0.8);
  -ms-transform: scale 0.8;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform-origin: top center;
}
#modal #tour ul li {
  color: white;
  text-transform: uppercase;
  font-size: 14pt;
  margin: 25px 0;
  font-weight: bold;
  letter-spacing: 2px;
  width: 100%;
}
#modal #tour ul li .wrap {
  display: table-cell;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
#modal #tour ul li .wrap.first {
  width: 400px;
}
#modal #tour ul li .wrap.second {
  width: 250px;
}
#modal #tour ul li .wrap span {
  display: inline-block;
  white-space: normal;
  margin: 0;
}
#modal #tour ul li .date {
  width: 300px;
}
#modal #tour ul li .venue,
#modal #tour ul li .country {
  width: 100%;
  font-style: oblique;
  font-weight: lighter;
  padding-right: 25px;
  box-sizing: border-box;
  text-align: left;
}
#modal #tour ul li .buy {
  vertical-align: middle;
  width: 100px;
  background: #EFB123;
  text-decoration: none;
  color: black;
  font-size: 8pt;
  letter-spacing: 3px;
  display: inline-block;
  line-height: 50px;
  padding: 0 25px;
  margin: 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
#modal #tour ul li .buy:hover {
  background: #f2c253;
  transition: all 0.3s ease-in-out;
}
#modal #tour ul li .buy.vip {
  border-left: 3px solid black;
}
#modal #photos {
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#modal #photos.show {
  align-items: flex-start;
  justify-content: flex-start;
}
#modal #photos img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  height: auto;
}
#modal #videos {
  width: 100%;
  overflow: hidden;
}
#modal #videos.show {
  justify-content: center;
}
#modal #videos iframe {
  position: relative;
  z-index: 2;
}
#modal #download.show {
  flex-direction: row;
}
#modal #download h2 {
  color: white;
  font-size: 62px;
  margin: 0;
}
#modal #download p {
  color: white;
  text-align: center;
  margin: 0;
  line-height: 30px;
}
#modal #download iframe {
  display: block;
  margin: 25px 0 0;
  padding: 0 25px 25px;
  background: white;
  overflow: hidden;
}

.downloads #logo,
.downloads #stage,
.downloads nav.hideMobile,
.downloads footer {
  display: none !important;
}

@media (max-width: 1440px) {
  #splash .half {
    transform: scale(0.8);
  }
}
@media (max-width: 1024px) {
  #splash .half {
    width: 100%;
  }
  #modal #tour {
    transform: scale(1);
  }
  #modal #tour.show {
    justify-content: flex-start;
  }
  #modal #tour ul {
    width: auto;
    padding-bottom: 150px;
    margin: 0 0 -2000px;
  }
  #modal #tour ul li {
    width: 300px;
    margin: 25px auto;
  }
  #modal #tour ul li .wrap {
    width: auto;
    display: block;
    font-size: 10pt;
    margin: 10px 0;
  }
  #modal #tour ul li .wrap.first {
    width: auto;
  }
  #modal #tour ul li .wrap span.venue, #modal #tour ul li .wrap span.date, #modal #tour ul li .wrap span.location, #modal #tour ul li .wrap span.continent {
    width: auto;
  }
  #modal #tour ul li .wrap span.date {
    display: block;
    font-size: 14pt;
  }
  #modal #tour ul li .wrap span.continent, #modal #tour ul li .wrap span.location {
    display: inline-block;
  }
  #modal #tour ul li .wrap span.continent:before {
    content: "-";
    display: inline-block;
    padding: 0 5px;
  }
  #modal #tour ul li .buy {
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    padding: 0;
  }
  #modal #tour ul li .buy.vip {
    border: none;
    margin-top: 10px;
  }
}
@media (max-width: 1024px) and (max-height: 1366px) {
  #splash {
    background-size: 75%;
    background-position: 150% 0;
  }
  #splash .half {
    width: 100%;
  }
  #splash .half #bgImage {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  #splash #artwork {
    display: none;
  }
}
@media (max-width: 1024px) and (max-height: 768px) {
  #splash {
    background-size: 75%;
    background-position: 150% 0;
  }
  #splash .half {
    width: 50%;
  }
  #splash .half #bgImage {
    display: none;
    width: 100%;
    max-width: 100%;
  }
  #splash #artwork {
    display: flex;
  }
}
@media (max-width: 768px) {
  body {
    overflow: hidden;
  }
  #splash .half {
    width: 100%;
    display: flex;
  }
  #splash .half #bgImage {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  #splash #artwork {
    display: none;
  }
  #modal h2 {
    font-size: 14pt;
    letter-spacing: 3px;
    line-height: 24px;
  }
  #modal #music {
    width: 100%;
    transform: scale(1);
  }
  #modal #music .album {
    margin: 25px auto;
    display: block;
  }
  #modal #music .album:last-child {
    padding-bottom: 150px;
  }
  #modal #tour {
    transform: scale(1);
  }
  #modal #tour.show {
    justify-content: center;
  }
  #modal #tour ul {
    width: auto;
    padding-bottom: 0;
  }
  #modal #tour ul li {
    width: 300px;
    margin: 25px auto;
  }
  #modal #tour ul li .wrap {
    width: auto;
    display: block;
    font-size: 10pt;
    margin: 10px 0;
  }
  #modal #tour ul li .wrap span.venue, #modal #tour ul li .wrap span.date, #modal #tour ul li .wrap span.location, #modal #tour ul li .wrap span.continent {
    width: auto;
  }
  #modal #tour ul li .wrap span.date {
    display: block;
    font-size: 14pt;
  }
  #modal #tour ul li .wrap span.continent, #modal #tour ul li .wrap span.location {
    display: inline-block;
  }
  #modal #tour ul li .wrap span.continent:before {
    content: "-";
    display: inline-block;
    padding: 0 5px;
  }
  #modal #tour ul li .buy {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 414px) {
  html,
  body {
    overflow: hidden;
  }
  .showMobile {
    display: block !important;
  }
  .hideMobile {
    display: none !important;
  }
  nav {
    padding-top: 15px;
    padding-right: 15px;
    background: none;
  }
  nav a, nav button {
    line-height: 40px;
  }
  nav.open {
    pointer-events: none;
  }
  nav.open .close {
    pointer-events: auto;
    transform: translate(0, 0);
    transition: all 0.5s ease-in-out;
  }
  nav.open .navLinks {
    transform: translate(35vh, 0);
    transition: all 0.5s ease-in-out;
  }
  nav .navLinks {
    transform: translate(0, 0);
    transition: all 0.5s ease-in-out;
  }
  nav .close {
    position: absolute;
    top: 25px;
    right: 25px;
    transform: translate(0, -100px);
    transition: all 0.5s ease-in-out;
    width: 25px;
    height: 25px;
  }
  nav .close:before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: white;
    transform: translate(0, 3px) rotate(45deg);
    border-radius: 3px;
  }
  nav .close:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: white;
    transform: rotate(-45deg);
    border-radius: 3px;
  }
  nav .close:hover {
    cursor: poiner !important;
  }
  nav.hideMobile {
    display: none !important;
  }
  #stage.open {
    transform: translate(0, 30vh);
  }
  #container:after {
    content: "";
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 10;
    background: linear-gradient(transparent, black);
    pointer-events: none;
  }
  #splash {
    overflow-y: scroll;
  }
  #splash .half {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #splash .half #title {
    padding: 0;
    transform: translate(0, 0);
    width: 100%;
  }
  #splash #musicLinks {
    position: relative;
    transform: translate(0, 0) scale(0.8);
    white-space: nowrap;
  }
  #splash button {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    line-height: 60px;
    margin: 0;
    padding: 0;
  }
  #splash #logoMarks {
    display: block;
    position: relative;
    width: 100%;
    bottom: 50px;
    left: 0;
    text-align: center;
  }
  #splash #logoMarks img {
    width: 40px;
  }
  #modal {
    display: block;
  }
  #modal h2 {
    font-size: 10pt;
    letter-spacing: 2px;
  }
  #modal #music {
    padding: 0;
  }
  #modal #tour {
    padding-top: 25px;
  }
  #modal #tour.show {
    justify-content: flex-start;
  }
  #modal #tour ul {
    margin: 0 0 -200px;
  }
  #modal #tour ul li {
    width: 225px;
  }
  #modal #tour ul li .wrap span.location, #modal #tour ul li .wrap span.country {
    display: inline-block;
    letter-spacing: 0;
    text-align: left;
  }
  #modal #tour ul li .wrap span.country {
    margin-left: 5px;
  }
  #modal #tour ul li .buy {
    line-height: 40px;
  }
  #modal #videos {
    overflow: hidden;
  }
  #modal #download {
    padding: 0 15px;
    box-sizing: border-box;
  }
  #modal #download.show {
    flex-direction: column;
  }
  #modal #download h2 {
    margin: 70px 0 25px;
    font-size: 48px;
  }
  #modal #download p {
    margin: 15px 0;
    line-height: normal;
  }
  #modal #download iframe {
    width: 100%;
    padding: 0 0 25px;
  }
  #App.in #social {
    top: 0;
  }
  #App.in #logo {
    left: 15px;
  }
  #App.in .emailForm {
    bottom: 15px;
  }
  #logo {
    position: absolute;
    top: 15px;
    left: -100%;
    height: auto;
    width: auto;
    text-align: left;
    display: block;
    z-index: 20;
    padding: 0;
    background: none;
  }
  #logo img {
    width: 75px;
  }
  #social {
    position: relative;
    right: auto;
    top: auto;
    text-align: right;
    margin-top: 10px;
  }
  #social div {
    font-size: 6pt;
  }
  #social a {
    display: inline-block;
    margin: 0 15px;
  }
  #social a#youtube {
    margin: 0;
  }
  #social a#youtube.showMobile {
    display: inline-block !important;
  }
  .emailForm {
    margin: 0 auto;
    padding: 0;
    display: block;
    bottom: -100%;
    width: 100%;
    text-align: center;
  }
  .emailForm h6 {
    width: 250px;
    margin: 0 auto;
    text-align: left;
  }
  .emailForm form {
    display: block;
  }
  .emailForm input {
    margin-bottom: 10px;
    border-radius: 0;
  }
  .emailForm .showMobile {
    color: white;
    text-transform: uppercase;
    font-size: 8pt;
    letter-spacing: 1px;
    vertical-align: middle;
    line-height: 21px;
  }
  .emailForm .showMobile a {
    height: auto;
    margin: 0;
    padding: 0;
    display: inline-block;
    opacity: 1;
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  .emailForm .showMobile a img {
    width: 100%;
    vertical-align: middle;
  }
  .emailForm .msg-alert p {
    margin: 0 0 5px;
  }
  .downloads .emailForm h6,
  .downloads .emailForm form {
    display: none;
  }
  .downloads #stage.open {
    transform: translate(0, 50vh);
  }
}
@media (max-width: 375px) and (max-height: 812px) {
  #splash {
    background-size: 100%;
    background-position: 50% 25px;
  }
  #splash .half {
    width: auto;
  }
  #splash .half #title {
    transform: translate(0, 0);
    width: 100%;
  }
}
@media (max-width: 375px) and (max-height: 667px) {
  #splash {
    background-size: 80%;
    background-position: 50% 25px;
  }
  #splash .half {
    width: auto;
  }
  #splash .half #title {
    transform: translate(0, 0);
    width: 100%;
  }
}
@media (max-width: 320px) {
  nav a, nav button {
    line-height: 35px;
  }
  #splash #musicLinks {
    transform: translate(0, 0);
    white-space: normal;
    box-sizing: border-box;
    padding: 0 15px;
  }
}

