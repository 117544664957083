.slick-slider {
    position: relative;
    width: 853px;
    margin: 0 auto;
    top: -50px;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        pointer-events: none;
        -webkit-overflow-scrolling: touch;
    }
    .slick-list {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        .slick-slide {
            position: relative;
            align-items: center;
            transition: all 0.3s ease-in-out;
            box-sizing: border-box;
            padding: 0 25px;
            display: inline-block;
            width: 100%;
            .slide {
                width: 100%;
                text-align: center;
                box-sizing: border-box;
                white-space: normal;
                .placeholder {
                    position: relative;
                    .playButton {
                        width: 100px;
                        height: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%) scale(1);
                        transition: all 0.3s ease-in-out;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                    &:hover {
                        cursor: pointer;
                        .playButton {
                            transform: translate(-50%,-50%) scale(1.1);
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
                iframe {
                    width: 100%;
                    height: 505px;
                }
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        border: none;
        background: none;
        text-indent: -9999px;
        z-index: 10;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
        &.slick-prev {
            left: -15px;
            border-right: 10px solid white;
        }
        &.slick-next {
            right: -15px;
            border-left: 10px solid white;
        }
        &:hover {
            opacity: 1;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }
    }
    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        transform: translate(0,-15px);
        z-index: 1;
        li {
            display: inline-block;
            width: auto;
            position: relative;
            transition: all 0.3s ease-in-out;
            width: 10px;
            height: 10px;
            margin: 0 15px 0 0;
            &:last-child {
                margin: 0;
            }
            button {
                width: 10px;
                height: 10px;
                display: block;
                border-radius: 50%;
                border: none;
                padding: 0;
                text-indent: -9999px;
                opacity: 0.5;
                background: white;
                transition: all 0.3s ease-in-out;
                &:hover {
                    opacity: 0.7;
                    cursor:  pointer;
                    transition: all 0.3s ease-in-out;
                }
            }
            &.slick-active {
                button {
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1280px) {
    .slick-slider {
        width: 640px;
        .slick-list {
            .slick-slide {
                .slide {
                    iframe {
                        height: 300px;
                    }
                }
            }
        }
    }
}
@media (max-width: 640px) {
    .slick-slider {
        top: 0;
        width: 100%;
        transform: translate(0,-100px);
        .slick-list {
            .slick-slide {
                .slide {
                    iframe {
                        width: 100%;
                        height: 200px;
                    }
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
        .slick-dots {
            transform: translate(0,0);
        }
    }
}