@media (max-width: 1440px) {
	#splash {
		.half {
			transform: scale(0.8);
		}
	}
}
@media (max-width: 1024px) {
	#splash {
		.half {
			width: 100%;
		}
	}
	#modal {
		#tour {
			transform: scale(1);
			&.show {
				justify-content: flex-start;
			}
			ul {
				width: auto;
				padding-bottom: 150px;
				margin: 0 0 -2000px;
				li {
					width: 300px;
					margin: 25px auto;
					.wrap {
						width: auto;
						display: block;
						font-size: 10pt;
						margin: 10px 0;
						&.first {
							width: auto;
						}
						span {
							&.venue,
							&.date,
							&.location,
							&.continent {
								width: auto;
							}
							&.date {
								display: block;
								font-size: 14pt;
							}
							&.continent,
							&.location {
								display: inline-block;
							}
							&.continent {
								&:before {
									content: "-";
									display: inline-block;
									padding: 0 5px;
								}
							}
						}
					}
					.buy {
						width: 100%;
						display: inline-block;
						margin: 0 auto;
						padding: 0;
						&.vip {
							border: none;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 1024px) and (max-height:1366px) {
	#splash {
		background-size: 75%;
		background-position: 150% 0;
		.half {
			width: 100%;
			#bgImage {
				display: block;
				width: 100%;
				max-width: 100%;
			}
		}
		#artwork {
			display: none;
		}
	}
}
@media (max-width: 1024px) and (max-height:768px) {
	#splash {
		background-size: 75%;
		background-position: 150% 0;
		.half {
			width: 50%;
		#bgImage {
				display: none;
				width: 100%;
				max-width: 100%;
			}
		}
		#artwork {
			display: flex;
		}
	}
}
@media (max-width: 768px) {
	body {
		overflow: hidden;
	}
	#splash {
		.half {
			width: 100%;
			display: flex;
			#bgImage {
				display: block;
				width: 100%;
				max-width: 100%;
			}
		}
		#artwork {
			display: none;
		}
	}
	#modal {
		h2 {
			font-size: 14pt;
			letter-spacing: 3px;
			line-height: 24px;
		}
		#music {
			width: 100%;
			transform: scale(1);
			.album {
				margin: 25px auto;
				display: block;
				&:last-child {
					padding-bottom: 150px;
				}
			}
		}
		#tour {
			transform: scale(1);
			&.show {
				justify-content: center;
			}
			ul {
				width: auto;
				padding-bottom: 0;
				li {
					width: 300px;
					margin: 25px auto;
					.wrap {
						width: auto;
						display: block;
						font-size: 10pt;
						margin: 10px 0;
						span {
							&.venue,
							&.date,
							&.location,
							&.continent {
								width: auto;
							}
							&.date {
								display: block;
								font-size: 14pt;
							}
							&.continent,
							&.location {
								display: inline-block;
							}
							&.continent {
								&:before {
									content: "-";
									display: inline-block;
									padding: 0 5px;
								}
							}
						}
					}
					.buy {
						width: 100%;
						display: block;
						margin: 0 auto;
						padding: 0;
					}
				}
			}
		}
	}
}
@media (max-width: 414px) {
	html,
	body {
		overflow: hidden;
	}
	.showMobile {
		display: block !important;
	}
	.hideMobile {
		display: none !important;
	}
	nav {
		padding-top: 15px;
		padding-right: 15px;
		background: none;
		a, button {
			line-height: 40px;
		}
		&.open {
			pointer-events: none;
			.close {
				pointer-events: auto;
				transform: translate(0,0);
				transition: all 0.5s ease-in-out;
			}
			.navLinks {
				transform: translate(35vh,0);
				transition: all 0.5s ease-in-out;
			}
		}
		.navLinks {
			transform: translate(0,0);
			transition: all 0.5s ease-in-out;
		}
		.close {
			position: absolute;
			top: 25px;
			right: 25px;
			transform: translate(0,-100px);
			transition: all 0.5s ease-in-out;
			width: 25px;
			height: 25px;
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 3px;
				background: white;
				transform: translate(0,3px)rotate(45deg);
				border-radius: 3px;
			}
			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 3px;
				background: white;
				transform: rotate(-45deg);
				border-radius: 3px;
			}
			&:hover {
				cursor: poiner !important;
			}
		}
		&.hideMobile {
			display: none !important;
		}
	}
	#stage {
		&.open {
			transform: translate(0,30vh);
		}
	}
	#container {
		&:after {
			content: "";
			display: block;
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 150px;
			z-index: 10;
			background: linear-gradient(transparent, black);
			pointer-events: none;
		}
	}
	#splash {
		overflow-y: scroll;
		.half {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			#title {
				padding: 0;
				transform: translate(0,0);
				width: 100%;
			}
		}
		#musicLinks {
			position: relative;
			transform: translate(0,0) scale(0.8);
			white-space: nowrap;
		}
		button {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			text-align: center;
			line-height: 60px;
			margin: 0;
			padding: 0;
		}
		#logoMarks {
			display: block;
			position: relative;
			width: 100%;
			bottom: 50px;
			left: 0;
			text-align: center;
			img {
				width: 40px;
			}
		}
	}
	#modal {
		display: block;
		h2 {
			font-size: 10pt;
			letter-spacing: 2px;
		}
		#music {
			padding: 0;
		}
		#tour {
			padding-top: 25px;
			&.show {
				justify-content: flex-start;
			}
			ul {
				margin: 0 0 -200px;
				li {
					width: 225px;
					.wrap {
						span {
							&.location,
							&.country {
								display: inline-block;
								letter-spacing: 0;
								text-align: left;
							}
							&.country {
								margin-left: 5px;
							}
						}
					}
					.buy {
						line-height: 40px;
					}
				}
			}
		}
		#videos {
			overflow: hidden;
		}
		#download {
			padding: 0 15px;
			box-sizing: border-box;
			&.show {
				flex-direction: column;
			}
			h2 {
				margin: 70px 0 25px;
				font-size: 48px;
			}
			p {
				margin: 15px 0;
				line-height: normal;
			}
			iframe {
				width: 100%;
				padding: 0 0 25px;
			}
		}
	}
	#App {
		&.in {
			#social {
				top: 0;
			}
			#logo {
				left: 15px;
			}
			.emailForm {
				bottom: 15px;
			}
		}
	}
	#logo {
		position: absolute;
		top: 15px;
		left: -100%;
		height: auto;
		width: auto;
		text-align: left;
		display: block;
		z-index: 20;
		padding: 0;
		background: none;
		img {
			width: 75px;
		}
	}
	#social {
		position: relative;
		right: auto;
		top: auto;
		text-align: right;
		margin-top: 10px;
		div {
			font-size: 6pt;
		}
		a {
			display: inline-block;
			margin: 0 15px;
			&#youtube {
				margin: 0;
				&.showMobile {
					display: inline-block !important;
				}
			}
		}
	}
	.emailForm {
		margin: 0 auto;
		padding: 0;
		display: block;
		bottom: -100%;
		width: 100%;
		text-align: center;
		h6 {
			width: 250px;
			margin: 0 auto;
			text-align: left;
		}
		form {
			display: block;
		}
		input {
			margin-bottom: 10px;
			border-radius: 0;
		}
		.showMobile {
			color: white;
			text-transform: uppercase;
			font-size: 8pt;
			letter-spacing: 1px;
			vertical-align: middle;
			line-height: 21px;
			a {
				height: auto;
				margin: 0;
				padding: 0;
				display: inline-block;
				opacity: 1;
				text-decoration: none;
				color: white;
				font-weight: bold;
				img {
					width: 100%;
					vertical-align: middle;
				}
			}
		}
		.msg-alert {
			p {
				margin: 0 0 5px;
			}
		}
	}
	.downloads {
		.emailForm {
			h6,
			form {
				display: none;
			}
		}
		#stage {
			&.open {
				transform: translate(0,50vh);
			}
		}
	}
}
@media (max-width: 375px) and (max-height: 812px) {
	#splash {
		background-size: 100%;
		background-position: 50% 25px;
		.half {
			width: auto;
			#title {
				transform: translate(0,0);
				width: 100%;
			}
		}
	}
}
@media (max-width: 375px) and (max-height: 667px) {
	#splash {
		background-size: 80%;
		background-position: 50% 25px;
		.half {
			width: auto;
			#title {
				transform: translate(0,0);
				width: 100%;
			}
		}
	}
}
@media (max-width: 320px) {
	nav {
		a, button {
			line-height: 35px;
		}
	}
	#splash {
		#musicLinks {
			transform: translate(0,0);
			white-space: normal;
			box-sizing: border-box;
			padding: 0 15px;
		}
	}
}