$tint: #28292d;

@import url("https://use.typekit.net/kmp8rqf.css");
@font-face {
    font-family: Universe;
    src: url("../fonts/universe.otf") format("opentype");
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100vh;
	background: #0d0d0d;
	font-family: "century-gothic", sans-serif;
	overflow: hidden;
}
a, button {
	font-family: "century-gothic", sans-serif;
	&:focus {
		outline: none;
	}
}
.showMobile {
	display: none;
}
.hideMobile {
	display: block;
}
.particles {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	height: 100vh;
}
#container {
	opacity: 1;
	transition: all 2s ease-in-out;
}
#stage {
	position: absolute;
	height: 100vh;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: url('../img/bg.png') no-repeat scroll bottom center / cover;
	transition: all 1s ease-in-out;
	pointer-events: none;
	&.open {
		transform: translate(0,55vh);
		transition: all 1.2s ease-in-out;
	}
}
#App {
	opacity: 0;
	transition: all 3s ease-in-out;
	&.in {
		opacity: 1;
		transition: all 3s ease-in-out;
		#logo {
			left: 0;
			transition: all 2.5s ease-in-out;
		}
		nav {
			right: 0;
			transition: all 2.5s ease-in-out;
		}
		#social {
			top: 25px;
			transition: all 2.5s ease-in-out;
		}
		#legal, .emailForm {
			bottom: 0;
			transition: all 2.5s ease-in-out;
		}
	}
}
#splash {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	box-sizing: border-box;
	overflow: hidden;
	background: #faf9ee;
	&.close {
		transform: translate(0,-100vh);
		transition: all 0.3s ease-in-out;
	}
	.half {
		width: 50%;
		height: 100vh;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;
	}
	#artwork {
		position: absolute;
		right: 50px;
		top: 0;
		height: 100%;
		width: 55%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
		}
	}
	button {
		background: #faf9ee;
		border: none;
		position: absolute;
		top: 25px;
		right: 25px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 5px;
		text-align: center;
		transition: all 0.3s ease-in-out;
		z-index: 999;
		font-weight: bold;
		&:hover {
			cursor: pointer;
			letter-spacing: 5.5px;
			transition: all 0.3s ease-in-out;
		}
	}
	#title {
		width: 80%;
		transform: translate(0,-25px);
	}
	#logoMarks {
		position: absolute;
		left: 25px;
		bottom: 25px;
		height: auto;
		z-index: 999;
		width: 200px;
		img {
			width: 40px;
			height: auto;
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px;
			opacity: 0.3;
			&#aftermath {
				width: 25px;
			}
		}
	}
	#musicLinks {
		display: block;
		text-align: center;
		transform: translate(0,-35px);
		p {
			text-transform: uppercase;
			letter-spacing: 10px;
			margin: 0;
			color: #818389;
			line-height: 60px;
			&.date {
				font-weight: bold;
				font-size: 24pt;
			}
			&.order {
				font-size: 12pt;
				font-weight: lighter;
			}
		}
		a {
			width: 35px;
			display: inline-block;
			margin: 0 auto;
			padding: 0 10px;
			vertical-align: middle;
			&#apple {
				width: 75px;
			}
			img {
				width: 100%;
				height: auto;
			}
			&#shop {
				display: block;
				width: auto;
				text-decoration: none;
				text-transform: uppercase;
				color: #818389;
				font-size: 24pt;
				margin-top: 60px;
				letter-spacing: 5px;
				span {
					font-size: 26pt;
				}
			}
		}
	}
	.image {
		position: relative;
		z-index: 1;
		video {
			border: none;
			height: auto;
		}
	}
}
#logo {
	position: fixed;
	left: -100%;
	padding-left: 25px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	z-index: 99;
	pointer-events: none;
	background: linear-gradient(to right, rgba(0,0,0,0.5), transparent);
	transition: all 2.5s ease-in-out;
	img {
		width: 120px;
		height: auto;
	}
}
nav {
	position: fixed;
	top: 0;
	right: -100%;
	padding-right: 25px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 12;
	pointer-events: none;
	background: linear-gradient(to left, rgba(0,0,0,0.5), transparent);
	transition: all 2.5s ease-in-out;
	&.hideMobile {
		display: flex !important;
	}
	button, a {
		display: block;
		border: none;
		background: none;
		color: white;
		margin: 0;
		padding: 0;
		font-size: 14pt;
		font-weight: bold;
		width: 200px;
		text-align: right;
		line-height: 30px;
		text-transform: uppercase;
		letter-spacing: 5px;
		transition: all 0.3s ease-in-out;
		text-decoration: none;
		pointer-events:auto;
		&:hover {
			cursor: pointer !important;
			color: #EFB123;
			transition: all 0.3s ease-in-out;
		}
		&.selected {
			color: #EFB123;
		}
	}
}
#social {
	position: fixed;
	top: -100%;
	right: 25px;
	z-index: 999;
	transition: all 2.5s ease-in-out;
	a {
		display: inline-block;
		margin: 0 8px;
		&#facebook {
			width: 10px;
			height: auto;
		}
		&#instagram {
			width: 18px;
			height: auto;
		}
		&#twitter {
			width: 22px;
			height: auto;
		}
		&#youtube {
			width: 55px;
			height: auto;
			margin: 0;
		}
		img {
			width: 100%;
			height: auto;
		}
		&.showMobile {
			display: none;
		}
		&.hideMobile {
			display: inline-block;
		}
	}
}
#legal {
	position: fixed;
	bottom: -100%;
	right: 25px;
	display: block;
	color: white;
	font-size: 8pt;
	letter-spacing: 3px;
	text-transform: uppercase;
	z-index: 20;
	text-align: right;
	transition: all 2.5s ease-in-out;
	span {
		font-size: 6pt;
		display: block;
		line-height: 15px;
	}
	a {
		height: auto;
		display: inline-block;
		margin: 0 auto;
		text-align: right;
		font-weight: bold;
		color: white;
		text-decoration: none;
		img {
			vertical-align: middle;
		}
	}
}
::placeholder {
    color: white;
    opacity: 1;
}
.emailForm {
	display: block;
	position: fixed;
	bottom: -100%;
	left: 0;
	z-index: 11;
	padding: 25px;
	transition: all 2.5s ease-in-out;
	-webkit-overflow-scrolling: touch;
	h6 {
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		color: white;
		font-weight: normal;
		font-size: 9px;
		letter-spacing: 1px;
	}
	input {
		background: none;
		border: none;
		border-bottom: 1px solid white;
		width: 175px;
		line-height: 24px;
		transform: translate(0,-1px);
		color: white;
		border-radius: 0;
		font-size: 16px;
		font-family: "century-gothic", sans-serif;
		&:focus {
			outline: none;
			background: none;
		}
	}
	button {
		width: auto;
		background: white;
		text-decoration: none;
		color: black;
		font-size: 8pt;
		letter-spacing: 1px;
		line-height: 25px;
		padding: 0 15px;
		margin: 0 0 0 7px;
		transform: translate(0,-1px);
		border: none;
		text-align: center;
		display: table-cell;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: #EFB123;
			transition: all 0.3s ease-in-out;
		}
	}
	.msg-alert {
		p {
			color: #EFB123 !important;
			margin: 5px 0 0;
			transition: all 1s ease-in-out;
		}
	}
}
#modal {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	overflow: hidden;
	transform: translate(0,-100vh);
	transition: all 1s ease-in-out;
	opacity: 0;
	&.open {
		transform: translate(0,0);
		transition: all 1.5s ease-in-out;
		opacity: 1;
	}
	h2 {
		font-size: 12pt;
		color: white;
		letter-spacing: 5px;
		text-align: center;
		text-transform: uppercase;
		margin: 25px 0 15px;
		padding: 0;
		font-weight: bold;
	}
	#music,
	#videos,
	#photos,
	#tour,
	#download {
		display: none;
		height: 100%;
		&.show {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
		}
	}
	#music {
		text-align: center;
		min-height: 100%;
		text-align: center;
		box-sizing: border-box;
		padding: 0 200px 150px;
		width: 100%;
		transform: scale(1);
		-ms-transform: scale (1);
		-webkit-transform:scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		overflow-y: scroll;
  		-webkit-overflow-scrolling: touch;
		&.show {
			display: block;
			justify-content: flex-start;
		}
		.album {
			display: inline-block;
			margin: 25px;
			width: 250px;
			text-align: center;
			margin: 25px 25px 0;
			img {
				max-width: 100%;
				display: block;
				margin: 0 auto;
			}
			a {
				width: 20px;
				height: auto;
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px;
				text-align: center;
				&.apple {
					width: 50px;
				}
			}
		}
	}
	#tour {
		width: 100%;
		box-sizing: border-box;
		overflow-y: scroll;
  		-webkit-overflow-scrolling: touch;
		ul {
			list-style: none;
			margin: 0 0 -100px;
			padding: 0;
			transform: scale(0.8);
			-ms-transform: scale (0.8);
			-webkit-transform:scale(0.8);
			-moz-transform: scale(0.8);
			-o-transform: scale(0.8);
			transform-origin: top center;
			li {
				color: white;
				text-transform: uppercase;
				font-size: 14pt;
				margin: 25px 0;
				font-weight: bold;
				letter-spacing: 2px;
				width: 100%;
				.wrap {
					display: table-cell;
					box-sizing: border-box;
					margin: 0;
					padding: 0;
					vertical-align: middle;
					&.first {
						width: 400px;
					}
					&.second {
						width: 250px;
					}
					span {
						display: inline-block;
						white-space: normal;
						margin: 0;
					}
				}
				.date {
					width: 300px;
				}
				.venue,
				.country {
					width: 100%;
					font-style: oblique;
					font-weight: lighter;
					padding-right: 25px;
					box-sizing: border-box;
					text-align: left;
				}
				.buy {
					vertical-align: middle;
					width: 100px;
					background: #EFB123;
					text-decoration: none;
					color: black;
					font-size: 8pt;
					letter-spacing: 3px;
					display: inline-block;
					line-height: 50px;
					padding: 0 25px;
					margin: 0;
					text-align: center;
					display: table-cell;
					transition: all 0.3s ease-in-out;
					&:hover {
						background: lighten(#EFB123, 10%);
						transition: all 0.3s ease-in-out;
					}
					&.vip {
						border-left: 3px solid black;
					}
				}
			}
		}
	}
	#photos {
		width: 100%;
		margin: 0 auto;
		overflow-y: scroll;
  		-webkit-overflow-scrolling: touch;
		&.show {
			align-items: flex-start;
			justify-content: flex-start;
		}
		img {
			max-width: 100%;
			display: block;
			margin: 0 auto;
			height: auto;
		}
	}
	#videos {
		width: 100%;
		overflow: hidden;
		&.show {
			justify-content: center;
		}
		iframe {
			position: relative;
			z-index: 2;
		}
	}
	#download {
		&.show {
			flex-direction: row;
		}
		h2 {
			color: white;
			font-size: 62px;
			margin: 0;
		}
		p {
			color: white;
			text-align: center;
			margin: 0;
			line-height: 30px;
		}
		iframe {
			display: block;
			margin: 25px 0 0;
			padding: 0 25px 25px;
			background: white;
			overflow: hidden;
		}
	}
}
.downloads {
	#logo,
	#stage,
	nav.hideMobile,
	footer {
		display: none !important;
	}
}